import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './DialogExtension.css';

export default class DialogExtensionComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props && this.props.showDialog ? (
                    <div id="dialogExtension">
                        <Card id="card">
                            <CardContent>
                                <Typography color="textSecondary">
                                    Você têm certeza que deseja finalizar essa chamada?
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={this.props.leaveSession}>
                                    Sim
                                </Button>

                                <Button size="small" onClick={this.props.cancelClicked}>
                                    Não
                                </Button>
                            </CardActions>
                        </Card>
                    </div>
                ) : null}
            </div>
        );
    }
}
