import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { BrowserRouter, Route } from "react-router-dom";

import registerServiceWorker from './registerServiceWorker';
import VideoRoomComponent from './components/video-room/VideoRoomComponent';

// https://www.sitepoint.com/react-router-complete-guide/

ReactDOM.render(
  <BrowserRouter>
    <Route exact path="/" component={VideoRoomComponent} />
  </BrowserRouter>,
  document.getElementById('root')
);

registerServiceWorker();
