import React, { Component } from 'react';
import axios from 'axios';

import './VideoRoomComponent.css';
import { OpenVidu } from 'openvidu-browser';

import { Detector } from "react-detect-offline";
import createSession from '../../services/manager';
import StreamComponent from '../stream/StreamComponent';

// import loadingLogo from '../../assets/gifs/loading.gif'
import planetunLogo from '../../assets/images/planetun.svg'

import UserModel from '../../models/user-model';
import OpenViduLayout from '../../layout/openvidu-layout';
import ToolbarComponent from '../toolbar/ToolbarComponent';
import DialogExtensionComponent from '../dialog-extension/DialogExtension';

var localUser = new UserModel();

const extractToken = (location) => {

    let rx = new RegExp("[?&]" + 'token' + "=([^&]+).*$");
    let token = location.search.match(rx);
    console.log('TOKEN', token[1]);
    return token[1];
}

class VideoRoomComponent extends Component {
    constructor(props) {
        super(props);

        this.hasBeenUpdated = false;
        this.layout = new OpenViduLayout();
        this.SERVER_CF = process.env.REACT_APP_CF_SERVER;

        let sessionName = this.props.sessionName ? this.props.sessionName : extractToken(this.props.location);
        let userName = this.props.user ? this.props.user : `SEGURADO-${sessionName}`;
        this.state = {
            mySessionId: sessionName,
            myUserName: userName,
            session: undefined,
            localUser: undefined,
            subscribers: [],
            finished: false,
            token: undefined,
            showDialog: false,
            awaiting: false,
            role: 'SEGURADO'
        };

        this.checkSize = this.checkSize.bind(this);
        this.joinSession = this.joinSession.bind(this);
        this.updateLayout = this.updateLayout.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);

        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);
        this.trackConnectivity = this.trackConnectivity.bind(this);

        this.nicknameChanged = this.nicknameChanged.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.openDialogExtension = this.openDialogExtension.bind(this);
        this.closeDialogExtension = this.closeDialogExtension.bind(this);
    }

    componentDidMount() {
        const openViduLayoutOptions = {
            maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
            minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
            fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
            bigClass: 'OV_big', // The class to add to elements that should be sized bigger
            bigPercentage: 0.8, // The maximum percentage of space the big ones should take up
            bigFixedRatio: false, // fixedRatio for the big ones
            bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
            bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
            bigFirst: true, // Whether to place the big one in the top left (true) or bottom right
            animate: true, // Whether you want to animate the transitions
        };

        this.layout.initLayoutContainer(document.getElementById('layout'), openViduLayoutOptions);
        window.addEventListener('beforeunload', this.onbeforeunload);
        window.addEventListener('resize', this.updateLayout);
        window.addEventListener('resize', this.checkSize);
        this.joinSession();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onbeforeunload);
        window.removeEventListener('resize', this.updateLayout);
        window.removeEventListener('resize', this.checkSize);
        this.leaveSession();
    }

    onbeforeunload(event) {
        this.leaveSession();
    }

    joinSession() {

        console.info('joining session');

        this.OV = new OpenVidu();

        this.setState(
            {
                session: this.OV.initSession(),
            },
            () => {
                this.subscribeToStreamCreated();

                this.connectToSession();
            },
        );
    }

    connectToSession() {
        if (this.props.token !== undefined) {
            console.log('token received: ', this.props.token);
            this.connect(this.props.token);
        } else {
            createSession(this.state.mySessionId).then((token) => {
                console.log(token);
                this.connect(token);
            }).catch((error) => {
                if (this.props.error) {
                    this.props.error({ error: error.error, messgae: error.message, code: error.code, status: error.status });
                }
                console.log('There was an error getting the token:', error.code, error.message);
                alert('There was an error getting the token:', error.message);
            });
        }
    }

    connect(token) {
        this.state.session
            .connect(
                token,
                { clientData: this.state.role },
            )
            .then(() => {
                this.connectWebCam();
            })
            .catch((error) => {
                if (this.props.error) {
                    this.props.error({ error: error.error, messgae: error.message, code: error.code, status: error.status });
                }
                alert('There was an error connecting to the session:', error.message);
                console.log('There was an error connecting to the session:', error.code, error.message);
            });
    }

    connectWebCam() {

        //TODO: downscale|upscale default resolution ( handle network issues )
        let publisher = this.OV.initPublisher(undefined, {
            audioSource: undefined,
            videoSource: undefined,
            publishAudio: localUser.isAudioActive(),
            publishVideo: localUser.isVideoActive(),
            resolution: '1280x720',
            frameRate: 30,
            insertMode: 'APPEND',
            mirror: false,
        });

        if (this.state.session.capabilities.publish) {
            this.state.session.publish(publisher).then(() => {
                if (this.props.joinSession) {
                    this.props.joinSession();
                }
            });
        }
        localUser.setNickname(this.state.myUserName);
        localUser.setConnectionId(this.state.session.connection.connectionId);
        localUser.setScreenShareActive(false);
        localUser.setStreamManager(publisher);
        this.subscribeToUserChanged();
        this.subscribeToStreamDestroyed();
        this.sendSignalUserChanged({ isScreenShareActive: localUser.isScreenShareActive() });

        this.state.session.on('signal:finished', (event) => {
            console.log('session ended', event);
            console.log('tracing', this.props);

            this.leaveSession();
        });

        this.handleNetworkChanges();

        this.setState({ localUser: localUser }, () => {
            this.state.localUser.getStreamManager().on('streamPlaying', (e) => {
                this.updateLayout();
                publisher.videos[0].video.parentElement.classList.remove('custom-class');
            });
        });
    }

    //TODO: set patterns to logs format
    handleNetworkChanges() {
        this.state.session.on('reconnecting', () => {

            console.warn('[session.reconnecting] Oops! Trying to reconnect to the session');
            this.setState({ awaiting: true })
        });

        this.state.session.on('reconnected', () => {

            console.warn('[session.reconnected] Hurray! You successfully reconnected to the session');

            this.applyReconnectionFailback();
            this.setState({ awaiting: false })
        });

        this.state.session.on('sessionDisconnected', (event) => {
            if (event.reason === 'networkDisconnect') {

                console.warn(`[session.networkDisconnect]: ${this.state.mySessionId} identification`);
                this.applyReconnectionFailback();

            } else {

                if(!this.state.finished){
                    // Disconnected from the session for other reason than a network drop
                    console.warn('[session.Unknown] - Dang-it...', event);
                    // this.applyReconnectionFailback();
                }
            }
        });
    }

    applyReconnectionFailback() {

        //TODO: awaiting reconnection ( visual feedback )
        console.log('[applyReconnectionFailback]', this.state.awaiting)

        //TODO: call connect and reset all states
        this.joinSession();
    }

    trackConnectivity(connected) {

        console.warn('[trackConnectivity]', connected, this.state);

        if (!connected && !this.state.awaiting) {

            this.state.awaiting == false ?
                this.setState({ awaiting: true }) :
                console.warn('[trackConnectivity] turned on awaiting status');
        }

        if (connected && this.state.awaiting) {

            this.state.awaiting == true ?
                this.setState({ awaiting: false }) :
                console.warn('[trackConnectivity] turned off awaiting status');
        }
    }

    async leaveSession() {

        this.closeDialogExtension();

        const mySession = this.state.session;
        const token = this.state.mySessionId;

        try {

            await axios.get(`${this.SERVER_CF}/apiToken.cfm?action=finalizaAtendimento&token=${token}&user=segurado`);

            await localUser.getStreamManager().stream.session.signal({
                data: 'segurado',
                type: 'finished',
            });

            if (mySession) {
                mySession.disconnect();
            }

            this.OV = null;
            this.setState({
                session: undefined,
                subscribers: [],
                myUserName: `SEGURADO-${token}`,
                localUser: undefined,
                token: undefined,
                finished: true,
                showDialog: false,
                awaiting: false,
                role: 'SEGURADO'
            });
            if (this.props.leaveSession) {
                this.props.leaveSession();
            }

            setTimeout(() => {
                window.location.href = `${this.SERVER_CF}/televistoria.cfm?token=${token}`;
            }, 5000)

        } catch (error) {
            throw error;
        }
    }

    closeDialogExtension() {
        this.setState({ showDialog: false });
    }

    openDialogExtension() {
        this.setState({ showDialog: true });
    }

    camStatusChanged() {
        localUser.setVideoActive(!localUser.isVideoActive());
        localUser.getStreamManager().publishVideo(localUser.isVideoActive());
        this.sendSignalUserChanged({ isVideoActive: localUser.isVideoActive() });
        this.setState({ localUser: localUser });
    }

    micStatusChanged() {
        localUser.setAudioActive(!localUser.isAudioActive());
        localUser.getStreamManager().publishAudio(localUser.isAudioActive());
        this.sendSignalUserChanged({ isAudioActive: localUser.isAudioActive() });
        this.setState({ localUser: localUser });
    }

    nicknameChanged(nickname) {
        let localUser = this.state.localUser;
        localUser.setNickname(nickname);
        this.setState({ localUser: localUser });
        this.sendSignalUserChanged({ nickname: this.state.localUser.getNickname() });
    }

    deleteSubscriber(stream) {
        const remoteUsers = this.state.subscribers;
        const userStream = remoteUsers.filter((user) => user.getStreamManager().stream === stream)[0];
        let index = remoteUsers.indexOf(userStream, 0);
        if (index > -1) {
            remoteUsers.splice(index, 1);
            this.setState({
                subscribers: remoteUsers,
            });
        }
    }

    subscribeToStreamCreated() {
        this.state.session.on('streamCreated', (event) => {
            const subscriber = this.state.session.subscribe(event.stream, undefined);
            var subscribers = this.state.subscribers;
            subscriber.on('streamPlaying', (e) => {
                subscriber.videos[0].video.parentElement.classList.remove('custom-class');
            });
            const newUser = new UserModel();
            newUser.setStreamManager(subscriber);
            newUser.setConnectionId(event.stream.connection.connectionId);
            newUser.setType('remote');
            const nickname = event.stream.connection.data.split('%')[0];
            newUser.setNickname(JSON.parse(nickname).clientData);
            subscribers.push(newUser);
            this.setState(
                {
                    subscribers: subscribers,
                },
                () => {
                    if (this.state.localUser) {
                        this.sendSignalUserChanged({
                            isAudioActive: this.state.localUser.isAudioActive(),
                            isVideoActive: this.state.localUser.isVideoActive(),
                            nickname: this.state.localUser.getNickname(),
                            isScreenShareActive: this.state.localUser.isScreenShareActive(),
                        });
                    }
                    this.updateLayout();
                },
            );
        });
    }

    subscribeToStreamDestroyed() {
        // On every Stream destroyed...
        this.state.session.on('streamDestroyed', (event) => {
            // Remove the stream from 'subscribers' array
            this.deleteSubscriber(event.stream);
            setTimeout(() => {

                // TODO: remove old references
                console.log('this.checkSomeoneShareScreen()');
            }, 20);
            event.preventDefault();
            this.updateLayout();
        });
    }

    subscribeToUserChanged() {
        this.state.session.on('signal:userChanged', (event) => {
            let remoteUsers = this.state.subscribers;
            remoteUsers.forEach((user) => {
                if (user.getConnectionId() === event.from.connectionId) {
                    const data = JSON.parse(event.data);

                    if (data.isAudioActive !== undefined) {
                        user.setAudioActive(data.isAudioActive);
                    }
                    if (data.isVideoActive !== undefined) {
                        user.setVideoActive(data.isVideoActive);
                    }
                    if (data.nickname !== undefined) {
                        user.setNickname(data.nickname);
                    }
                    if (data.isScreenShareActive !== undefined) {
                        user.setScreenShareActive(data.isScreenShareActive);
                    }
                }
            });
            this.setState(
                {
                    subscribers: remoteUsers,
                },
                () => console.log('checkSomeoneShareScreen()'),
            );
        });
    }

    updateLayout() {
        setTimeout(() => {
            this.layout.updateLayout();
        }, 20);
    }

    sendSignalUserChanged(data) {
        const signalOptions = {
            data: JSON.stringify(data),
            type: 'userChanged',
        };
        this.state.session.signal(signalOptions);
    }

    toggleFullscreen() {
        const document = window.document;
        const fs = document.getElementById('container');
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            if (fs.requestFullscreen) {
                fs.requestFullscreen();
            } else if (fs.msRequestFullscreen) {
                fs.msRequestFullscreen();
            } else if (fs.mozRequestFullScreen) {
                fs.mozRequestFullScreen();
            } else if (fs.webkitRequestFullscreen) {
                fs.webkitRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    checkSize() {
        if (document.getElementById('layout').offsetWidth <= 700 && !this.hasBeenUpdated) {
            // this.toggleChat('none');
            this.hasBeenUpdated = true;
        }
        if (document.getElementById('layout').offsetWidth > 700 && this.hasBeenUpdated) {
            this.hasBeenUpdated = false;
        }
    }

    render() {
        const mySessionId = this.state.mySessionId;
        const localUser = this.state.localUser;

        return (
            <div id='session'>
                <img src={planetunLogo} className="App-logo" alt="logo" onClick={this.toggleCamera} />

                <Detector
                    onChange={this.trackConnectivity}
                    render={({ online }) => (
                        <div>
                            {console.log('[current connection] online:', online)}
                        </div>
                    )} />

                {this.state.finished == false ?
                    <div>
                        <ToolbarComponent
                            user={localUser}
                            sessionId={mySessionId}
                            screenShare={this.screenShare}
                            stopScreenShare={this.stopScreenShare}
                            camStatusChanged={this.camStatusChanged}
                            micStatusChanged={this.micStatusChanged}
                            toggleFullscreen={this.toggleFullscreen}
                            showNotification={this.state.messageReceived}
                            openDialogExtension={this.openDialogExtension}
                            toggleChat={this.toggleChat} />

                        <DialogExtensionComponent showDialog={this.state.showDialog} leaveSession={this.leaveSession} cancelClicked={this.closeDialogExtension} />

                        {this.state.awaiting === true ?

                            <div className="awaiting-text">
                                    <div>
                                        <label>reconectando vídeo chamada...</label>
                                    </div>
                                    <br/>
                                    {/* <img src={loadingLogo} alt="loading..." /> */}
                                </div> :
                            <div></div>
                        }
                        <div className={this.state.awaiting ? "awaiting-modal" : null}>
                            <div id="layout" className="bounds">
                                {localUser !== undefined && localUser.getStreamManager() !== undefined && (
                                    <div className="OT_root OT_publisher custom-class" id="localUser">
                                        <StreamComponent user={localUser} handleNickname={this.nicknameChanged} />
                                    </div>
                                )}

                                {this.state.subscribers.map((sub, i) => (
                                    <div key={i} className="OT_root OT_publisher custom-class" id="remoteUsers">
                                        <StreamComponent user={sub} streamId={sub.streamManager.stream.streamId} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> :
                    <div className='finishedInfo'>
                        <h2>Solicitação Enviada</h2>
                        <br></br>
                        <label>Sua solicitação se encontra em análise pela nossa equipe técnica.</label>
                        <br></br>
                        <label>Redirecionando...</label>
                    </div>
                }
            </div>
        );
    }
}

export default VideoRoomComponent;


