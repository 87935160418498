import http from './index';

async function createSession(sessionId) {
    return new Promise((resolve, reject) => {

        http.post(`/sessions`, {sessionId})
            .then((response) => {
                console.log(response);
                resolve(response.data.token);
            })
            .catch((response) => {
                let error = Object.assign({}, response);
                if (error.response && error.response.status === 409) {
                    console.log('STATUS 409', error.response.status)
                    resolve(sessionId);
                } else {
                    console.log(error);
                    console.warn(
                        'No connection to OpenVidu Server. This may be a certificate error at ' + http.baseURL,
                    );
                    if (
                        window.confirm(
                            'No connection to OpenVidu Server. This may be a certificate error at "' +
                            http.baseURL +
                            '"\n\nClick OK to navigate and accept it. ' +
                            'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                            http.baseURL +
                            '"',
                        )
                    ) {
                        window.location.assign(http.baseURL + '/accept-certificate');
                    }
                }
            });
    });
}

export default createSession;
