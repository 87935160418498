import React, { Component } from 'react';
import './ToolbarComponent.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';

import { Detector } from "react-detect-offline";

import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';

import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';

import IconButton from '@material-ui/core/IconButton';

export default class ToolbarComponent extends Component {
    constructor(props) {
        super(props);

        this.state = { fullscreen: false, awaiting: false };
        this.camStatusChanged = this.camStatusChanged.bind(this);
        this.micStatusChanged = this.micStatusChanged.bind(this);

        this.screenShare = this.screenShare.bind(this);
        this.leaveSession = this.leaveSession.bind(this);
        this.stopScreenShare = this.stopScreenShare.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.trackConnectivity = this.trackConnectivity.bind(this);
    }

    micStatusChanged() {
        this.props.micStatusChanged();
    }

    camStatusChanged() {
        this.props.camStatusChanged();
    }

    screenShare() {
        this.props.screenShare();
    }

    stopScreenShare() {
        this.props.stopScreenShare();
    }

    toggleFullscreen() {
        this.setState({ fullscreen: !this.state.fullscreen });
        this.props.toggleFullscreen();
    }

    leaveSession() {
        this.props.leaveSession();
    }

    trackConnectivity(connected) {

        console.warn('[trackConnectivity]', connected, this.state);

        if (!connected && !this.state.awaiting) {

            this.state.awaiting == false ?
                this.setState({ awaiting: true }) :
                console.warn('[trackConnectivity] turned on awaiting status');
        }

        if (connected && this.state.awaiting) {

            this.state.awaiting == true ?
                this.setState({ awaiting: false }) :
                console.warn('[trackConnectivity] turned off awaiting status');
        }
    }

    render() {
        const mySessionId = this.props.sessionId;
        const localUser = this.props.user;
        return (
            <AppBar className="toolbar" id="header">

                <Detector
                    onChange={this.trackConnectivity}
                    render={({ online }) => (
                        <div>
                            {console.log('[current connection] online:', online)}
                        </div>
                    )} />

                <Toolbar className="toolbar">
                    <div id="navSessionInfo">

                        {this.props.sessionId && <div id="titleContent">
                            <span id="session-title">{mySessionId}</span>
                        </div>}
                    </div>

                    {this.state.awaiting === true ? <div className="awaiting-modal"></div> :
                        <div className="buttonsContent">
                            {console.log('TOOLBAR', this.state.awaiting)}

                            <IconButton color="inherit" className="navButton" id="navMicButton" onClick={this.micStatusChanged} >
                                {localUser !== undefined && localUser.isAudioActive() ? <Mic /> : <MicOff color="secondary" />}
                            </IconButton>

                            <IconButton color="secondary" className="navButton" onClick={this.props.openDialogExtension} id="navLeaveButton">
                                <PowerSettingsNew />
                            </IconButton>

                            <IconButton color="inherit" className="navButton" id="navCamButton" onClick={this.camStatusChanged}>
                                {localUser !== undefined && localUser.isVideoActive() ? (
                                    <Videocam />
                                ) : (
                                        <VideocamOff color="secondary" />
                                    )}
                            </IconButton>
                        </div>}
                </Toolbar>
            </AppBar>
        );
    }
}
