import axios from 'axios';

const SERVICE_URL = process.env.REACT_APP_MANAGER_SERVICE;
const MICRO_SERVICES_HOST = process.env.REACT_APP_MICRO_SERVICES_HOST;

/*
 * You can customize Axios settings
 * Ver mais em https://github.com/axios/axios
 */
const http = axios.create({
  baseURL: `${MICRO_SERVICES_HOST}/${SERVICE_URL}/api`,
});

// Exports Axios object to be used by the services
export default http;